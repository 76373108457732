.toolbar {
  height: 70px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(21, 21, 22, 0.966);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  box-sizing: border-box;
  z-index: 90;
  opacity: 0.85;
}

.toolbar-logo {
  height: 100%;
}

.logo {
  padding: 8px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 1rem;
}

@media (max-width: 759px) {
  .toolbar-desktop {
    display: none;
  }
}

.navbar {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  font-family: "Mate SC", serif;
}

.listitem {
  margin: 4rem 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}
.listitem a {
  margin: 2rem;
  color: white;
  text-decoration: none;
  width: 100%;
  font-size: 1.75rem;
  box-sizing: border-box;
  display: block;
}
.listitem a:hover,
.listitem a:active,
.listitem a.active {
  color: #fc520f;
}

.listitem-menu {
  margin: 4rem 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  position: relative;
}
.listitem-menu a {
  margin: 1.75rem;
  color: white;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
  font-size: 1.75rem;
}
.listitem-menu a:hover,
.listitem-menu a:active,
.listitem-menu a.active {
  color: #fc520f;
}

.listitem-menu:hover > .menudrawer {
  display: block;
}

/*This is phone css */
@media (max-width: 759px) {
  .navbar {
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100%;
  }
  .listitem {
    margin: 10px 0;
    box-sizing: border-box;
    display: block;
    width: 100%;
  }
  .listitem a {
    color: white;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
  }
  .listitem a:hover,
  .listitem a:active,
  .listitem a.active {
    color: #fc520f;
  }
}

.menu-dropdown-icon {
  font-size: 1.5rem;
  padding-left: 0.2rem;
}

.sidedrawer {
  position: fixed;
  width: 260px;
  max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 200;
  background-color: rgba(21, 21, 22, 0.966);
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.25s ease-out;
}

.sidedrawer-navbar {
  display: flex;
  margin-left: 2rem;
}

@media (min-width: 760px) {
  .sidedrawer {
    display: none;
  }
}

.open-sidedrawer {
  transform: translateX(0);
}

.close-sidedrawer {
  transform: translateX(-100%);
}

.hamburger {
  width: 40px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 15px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.hamburger div {
  width: 90%;
  height: 4px;
  background-color: white;
}

@media (min-width: 760px) {
  .hamburger {
    display: none;
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
}

.Loader,
.Loader:before,
.Loader:after {
  border-radius: 50%;
}

.Loader {
  color: #fc520f;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.Loader:before,
.Loader:after {
  position: absolute;
  content: "";
}

.Loader:before {
  width: 5.2em;
  height: 10.2em;
  background-color: #e6e6e6;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}

.Loader:after {
  width: 5.2em;
  height: 10.2em;
  background-color: #e6e6e6;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}

@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
.home-primary {
  display: flex;
  width: 100%;
  min-height: 100vh;
  position: relative;
  background-image: linear-gradient(
    to right bottom,
    rgba(32, 31, 31, 0.7),
    rgba(29, 28, 28, 0.7)
  );
}

.home-img {
  position: absolute;
  width: 100%;
  z-index: -1;
  height: 100%;
  object-fit: cover;
  left: 0;
  top: 0;
  animation-name: multiple-image-crossfade;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 32s;
}
.home-img:nth-of-type(1) {
  animation-delay: 24s;
}
.home-img:nth-of-type(2) {
  animation-delay: 16s;
}
.home-img:nth-of-type(3) {
  animation-delay: 8s;
}
.home-img:nth-of-type(4) {
  animation-delay: 0;
}

@keyframes multiple-image-crossfade {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.img-background {
  width: 100%;
  min-height: 100vh;
  background-size: fill;
  background-position: top;
  position: absolute;
  left: 0;
  top: 0;
}

.fade-enter {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: opacity 1.5s ease-in;
}

.fade-exit {
  opacity: 1;
  transform: translateX(0%);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 1.5s ease-out;
}

.wf-loading .title-primary-main {
  font-family: "Noto Sans HK", sans-serif;
  visibility: hidden;
}

.wf-active .title-primary-main {
  visibility: visible;
}

.title-primary {
  text-align: center;
  margin: 6.5rem auto;
  color: #fc520f;
  display: block;
  backface-visibility: hidden;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.title-primary-main {
  display: block;
  font-size: 6rem;
  font-weight: 400;
  letter-spacing: 0.25rem;
  text-transform: uppercase;
  animation-name: moveInLeft;
  animation-duration: 2s;
  animation-timing-function: ease-out;
}
@media only screen and (max-width: 37.5em) {
  .title-primary-main {
    font-size: 5rem;
  }
}
.title-primary-sub {
  display: block;
  font-size: 8.5rem;
  font-weight: 700;
  letter-spacing: 1rem;
  white-space: nowrap;
  text-transform: uppercase;
  animation-name: moveInRight;
  animation-duration: 2s;
  animation-timing-function: ease-out;
}
@media only screen and (max-width: 37.5em) {
  .title-primary-sub {
    letter-spacing: 0.2rem;
    font-size: 7.5rem;
  }
}
.title-primary-footer {
  margin-top: 1rem;
  display: block;
  color: #ece7e7;
  font-weight: 700;
  letter-spacing: 0.25rem;
  animation-name: moveInTop;
  animation-duration: 1s;
  animation-timing-function: ease-out;
}
.title-primary-footer-1 {
  margin-top: 1rem;
  display: inherit;
  font-size: 4rem;
}
@media only screen and (max-width: 37.5em) {
  .title-primary-footer-1 {
    font-size: 3rem;
  }
}
.title-primary-footer-3 {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  gap: 1rem;
}

.wf-loading .title-primary-main {
  font-family: "Noto Sans HK", sans-serif;
  visibility: hidden;
}

.wf-active .title-primary-main {
  font-family: "Noto Sans HK", sans-serif;
  visibility: visible;
}

.wf-loading .title-primary-sub {
  font-family: "Baloo Tammudu 2";
  visibility: hidden;
}

.wf-active .title-primary-sub {
  font-family: "Baloo Tammudu 2";
  visibility: visible;
}

.wf-loading .title-primary-footer-1 {
  font-family: "Cantarell", sans-serif;
  visibility: hidden;
}

.wf-active .title-primary-footer-1 {
  font-family: "Cantarell", sans-serif;
  visibility: visible;
}

/* animations */
@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translate(-10rem, 0);
  }
  80% {
    opacity: 0.5;
    transform: translate(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translate(10rem, 0);
  }
  80% {
    opacity: 0.5;
    transform: translate(1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translate(0, -3rem);
  }
  80% {
    opacity: 0.5;
    transform: translate(0);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.home-footer {
  background-color: #252423;
  min-height: 50vh;
}

.home-location {
  background-color: #252423;
  min-height: 70vh;
}

.home-aboutus {
  background: linear-gradient(
    to right bottom,
    rgba(223, 131, 56, 0.4),
    rgba(196, 82, 25, 0.9)
  );
  min-height: 100vh;
}

.home-menu {
  background-color: #e6e6e6;
  background-image: radial-gradient(#fc520f 2px, transparent 2px),
    radial-gradient(#fc520f 2px, #e6e6e6 2px);
  background-size: 80px 80px;
  background-position: 0 0, 40px 40px;
  min-height: 100vh;
}

.home-gallery {
  background: linear-gradient(
    to right bottom,
    rgba(223, 131, 56, 0.4),
    rgba(196, 82, 25, 0.9)
  );
  min-height: 100vh;
}

.icon-background {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  width: 8rem;
  height: 8rem;
}

.icon-background:hover {
  background-color: #fc520f;
}

.aboutus {
  display: flex;
  flex-wrap: wrap;
  scroll-margin-top: 70px;
  justify-content: center;
  height: auto;
}
@media only screen and (max-width: 950px) {
  .aboutus {
    align-items: center;
    height: auto;
    flex-direction: column;
  }
}

.aboutus-header {
  width: 100%;
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  margin-top: 2.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-family: "Fredoka One", cursive;
}

.aboutus-body {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 950px) {
  .aboutus-body {
    align-items: center;
    height: auto;
    flex-direction: column;
  }
}

.aboutus-description-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 4rem;
  text-align: center;
  margin-top: 2rem;
}

.aboutus-description-title {
  font-size: 3rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 950px) {
  .aboutus-description-title {
    margin-bottom: 2rem;
  }
}

.aboutus-description {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.aboutus-img {
  border-radius: 4px;
  width: 50%;
  height: 70vh;
  margin: 2rem 4rem;
  object-fit: cover;
}
@media only screen and (max-width: 950px) {
  .aboutus-img {
    width: 90%;
    height: auto;
  }
}

.menu {
  display: flex;
  flex-wrap: wrap;
  scroll-margin-top: 70px;
  justify-content: center;
  height: auto;
}
@media only screen and (max-width: 750px) {
  .menu {
    align-items: center;
    flex-direction: column;
  }
}

.menu-header {
  width: 100%;
  font-size: 3.5rem;
  margin-bottom: 1.5rem;
  margin-top: 2.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-family: "Fredoka One", cursive;
}

.menu-item-card-0 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 15rem;
  margin: 1rem;
  background-image: linear-gradient(
      to right bottom,
      rgba(32, 31, 31, 0.6),
      rgba(29, 28, 28, 0.6)
    ),
    url(../imgs/food_menu/appetizers/1.JPG);
  background-size: cover;
  background-position: top;
  color: black;
  text-decoration: none;
  padding: 2.5rem;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}
.menu-item-card-0:hover {
  transform: translateY(-0.5rem) scale(1.03);
  border: #cf7022 4px solid;
}
@media only screen and (max-width: 750px) {
  .menu-item-card-0 {
    width: 35rem;
    height: 25rem;
  }
}

.menu-item-card-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 15rem;
  margin: 1rem;
  background-image: linear-gradient(
      to right bottom,
      rgba(32, 31, 31, 0.6),
      rgba(29, 28, 28, 0.6)
    ),
    url(../imgs/food_menu/noodlesoup/19.JPG);
  background-size: cover;
  background-position: top;
  color: black;
  text-decoration: none;
  padding: 2.5rem;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}
.menu-item-card-1:hover {
  transform: translateY(-0.5rem) scale(1.03);
  border: #cf7022 4px solid;
}
@media only screen and (max-width: 750px) {
  .menu-item-card-1 {
    width: 35rem;
    height: 25rem;
  }
}

.menu-item-card-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 15rem;
  margin: 1rem;
  background-image: linear-gradient(
      to right bottom,
      rgba(32, 31, 31, 0.6),
      rgba(29, 28, 28, 0.6)
    ),
    url(../imgs/food_menu/riceplates/21.JPG);
  background-size: cover;
  background-position: top;
  color: black;
  text-decoration: none;
  padding: 2.5rem;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}
.menu-item-card-2:hover {
  transform: translateY(-0.5rem) scale(1.03);
  border: #cf7022 4px solid;
}
@media only screen and (max-width: 750px) {
  .menu-item-card-2 {
    width: 35rem;
    height: 25rem;
  }
}

.menu-item-card-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 15rem;
  margin: 1rem;
  background-image: linear-gradient(
      to right bottom,
      rgba(32, 31, 31, 0.6),
      rgba(29, 28, 28, 0.6)
    ),
    url(../imgs/food_menu/bread/29.JPG);
  background-size: cover;
  background-position: top;
  color: black;
  text-decoration: none;
  padding: 2.5rem;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}
.menu-item-card-3:hover {
  transform: translateY(-0.5rem) scale(1.03);
  border: #cf7022 4px solid;
}
@media only screen and (max-width: 750px) {
  .menu-item-card-3 {
    width: 35rem;
    height: 25rem;
  }
}

.menu-item-card-4 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 15rem;
  margin: 1rem;
  background-image: linear-gradient(
      to right bottom,
      rgba(32, 31, 31, 0.6),
      rgba(29, 28, 28, 0.6)
    ),
    url(../imgs/food_menu/vermicelli/30.JPG);
  background-size: cover;
  background-position: top;
  color: black;
  text-decoration: none;
  padding: 2.5rem;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}
.menu-item-card-4:hover {
  transform: translateY(-0.5rem) scale(1.03);
  border: #cf7022 4px solid;
}
@media only screen and (max-width: 750px) {
  .menu-item-card-4 {
    width: 35rem;
    height: 25rem;
  }
}

.menu-item-card-5 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 15rem;
  margin: 1rem;
  background-image: linear-gradient(
      to right bottom,
      rgba(32, 31, 31, 0.6),
      rgba(29, 28, 28, 0.6)
    ),
    url(../imgs/food_menu/side_order_bbq_pork.JPG);
  background-size: cover;
  background-position: top;
  color: black;
  text-decoration: none;
  padding: 2.5rem;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}
.menu-item-card-5:hover {
  transform: translateY(-0.5rem) scale(1.03);
  border: #cf7022 4px solid;
}
@media only screen and (max-width: 750px) {
  .menu-item-card-5 {
    width: 35rem;
    height: 25rem;
  }
}

.menu-item-card-6 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 25rem;
  margin: 1rem;
  background-image: linear-gradient(
      to right bottom,
      rgba(32, 31, 31, 0.6),
      rgba(29, 28, 28, 0.6)
    ),
    url(../imgs/food_menu//beverages/three_color_drink.JPG);
  background-size: cover;
  background-repeat: no-repeat;
  color: black;
  text-decoration: none;
  padding: 2.5rem;
  border-radius: 3px;
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);
  transition: transform 0.3s;
}
.menu-item-card-6:hover {
  transform: translateY(-0.5rem) scale(1.03);
  border: #cf7022 4px solid;
}
@media only screen and (max-width: 750px) {
  .menu-item-card-6 {
    width: 20rem;
  }
}

.menu-item-card-name {
  font-size: 2.75rem;
  font-family: "Noto Serif TC", serif;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
  color: #fc520f;
}

.gallery {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 770px) {
  .gallery {
    align-items: center;
    height: auto;
    flex-direction: column;
  }
}

.gallery-carousel {
  scroll-margin-top: 70px;
  margin: 2rem;
  width: 50%;
  height: auto;
}
@media only screen and (max-width: 720px) {
  .gallery-carousel {
    width: 50rem;
    margin-top: 8rem;
  }
}
@media only screen and (max-width: 500px) {
  .gallery-carousel {
    width: 40rem;
    margin-top: 10rem;
  }
}

.gallery-description {
  display: flex;
  width: 50%;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.gallery-description-header {
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-size: 3rem;
  font-family: "Fredoka One", cursive;
}
@media only screen and (max-width: 750px) {
  .gallery-description-header {
    font-size: 2.5rem;
    width: 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .gallery-description-header {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 750px) {
  .gallery-description {
    display: none;
  }
}

.top-seller-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
  font-family: "Caveat", cursive;
  width: 60%;
}
@media only screen and (max-width: 750px) {
  .top-seller-list {
    width: 80%;
  }
}
.top-seller-list li {
  font-size: 2.5rem;
  margin: 0.75rem 0;
}
@media only screen and (max-width: 1200px) {
  .top-seller-list li {
    font-size: 2.15rem;
  }
}

.entire-gallery-btn:link,
.entire-gallery-btn:visited {
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem 1.5rem;
  display: inline-block;
  width: 40%;
  margin-bottom: 1rem;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6rem;
  border: solid black 0.2rem;
}
@media only screen and (min-width: 750px) {
  .entire-gallery-btn:link,
  .entire-gallery-btn:visited {
    display: none;
  }
}

.entire-gallery-btn:hover {
  transform: translateY(-0.3rem);
  box-shadow: 0 1rem 2rem black;
  background-color: #fc520f;
  color: #f1ecec;
}
.entire-gallery-btn:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.entire-gallery-btn:active {
  transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem black;
}

.entire-gallery-btn--white {
  background-color: white;
  color: #fc520f;
}

.entire-gallery-btn::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all, 0.4s;
}

.entire-gallery-btn-1:link,
.entire-gallery-btn-1:visited {
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem 1.5rem;
  display: inline-block;
  width: 40%;
  margin-bottom: 1rem;
  transition: all 0.2s;
  position: relative;
  font-size: 1.6rem;
  border: solid black 0.2rem;
}

.entire-gallery-btn-1:hover {
  transform: translateY(-0.3rem);
  box-shadow: 0 1rem 2rem black;
  background-color: #fc520f;
  color: #f1ecec;
}
.entire-gallery-btn-1:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
}

.entire-gallery-btn-1:active {
  transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 1rem black;
}

.entire-gallery-btn-1--white {
  background-color: white;
  color: #fc520f;
}

.entire-gallery-btn-1::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all, 0.4s;
}

.gallery-top-seller-icon {
  font-size: 3.5rem;
  padding-left: 0.5rem;
  color: #fc520f;
}
@media only screen and (max-width: 750px) {
  .gallery-top-seller-icon {
    font-size: 2.5rem;
  }
}
@media only screen and (max-width: 1200px) {
  .gallery-top-seller-icon {
    font-size: 3rem;
  }
}

.entiregallery {
  background-color: #e6e6e6;
  scroll-margin-top: 70px;
  justify-content: center;
  margin-top: 7rem;
}

.entiregallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
}

.thumbnail-image {
  cursor: pointer;
  margin: 2rem;
  border-radius: 4px;
  object-fit: contain;
  width: 20%;
  height: auto;
}
@media only screen and (max-width: 900px) {
  .thumbnail-image {
    width: 30rem;
  }
}

.location {
  height: 100%;
  scroll-margin-top: 70px;
}

.contact-main {
  display: flex;
  justify-content: space-around;
  color: white;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  text-align: center;
  scroll-margin-top: 70px;
}
@media only screen and (max-width: 750px) {
  .contact-main {
    align-items: center;
    height: auto;
    flex-direction: column;
  }
}

.contact-header {
  font-size: 5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-decoration: underline;
  text-underline-offset: 10px;
  color: #fc520f;
  text-decoration-color: white;
  text-decoration-thickness: 2.5px;
  white-space: nowrap;
  font-family: "Viaoda Libre", cursive;
}

.contact-column {
  display: flex;
  width: 33%;
  justify-content: flex-start;
  flex-direction: column;
}
@media only screen and (max-width: 750px) {
  .contact-column {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.contact-address-item {
  font-size: 2rem;
  padding: 0 4rem;
  gap: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.contact-address-text {
  font-size: 2rem;
  padding: 0 8rem;
  flex: 1;
  align-self: flex-start;
  flex-flow: row nowrap;
  white-space: nowrap;
  overflow: hidden;
}
@media only screen and (max-width: 750px) {
  .contact-address-text {
    align-self: center;
  }
}

.contact-phone-item {
  font-size: 2rem;
  margin: 1rem 0;
  padding: 0 4rem;
  gap: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.contact-email-item {
  font-size: 2rem;
  margin: 1rem 0;
  padding: 0 4rem;
  gap: 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
}

.businessHours-header {
  font-size: 5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-decoration: underline;
  text-underline-offset: 10px;
  color: #fc520f;
  text-decoration-color: white;
  text-decoration-thickness: 2.5px;
  white-space: nowrap;
  font-family: "Viaoda Libre", cursive;
}

.businessHours-column {
  display: flex;
  width: 33%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.businessHours-body-1 {
  font-size: 2.25rem;
  margin: 1rem;
}

.businessHours-body-2 {
  font-size: 2.25rem;
  margin: 1rem;
}

.businessHours-body-3 {
  font-size: 2.25rem;
  margin: 1rem;
}

.socialLinks-header {
  font-size: 5rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  text-decoration: underline;
  text-underline-offset: 10px;
  color: #fc520f;
  text-decoration-color: white;
  text-decoration-thickness: 2.5px;
  white-space: nowrap;
  font-family: "Viaoda Libre", cursive;
}

.socialLinks-column {
  display: flex;
  width: 33%;
  align-items: center;
  flex-direction: column;
}

.socialLinks-items {
  display: flex;
  margin: 1rem;
  gap: 1rem;
}

.copyright-footer {
  width: 100%;
  color: white;
  text-align: right;
  font-size: 1.25rem;
  padding: 0 0;
}
@media only screen and (max-width: 750px) {
  .copyright-footer {
    text-align: center;
  }
}

.entire-menu {
  box-sizing: border-box;
  width: 95%;
  margin: 0.9em auto;
}

h1 {
  margin-top: 10%;
  justify-content: center;
  display: flex;
}

h2 {
  margin-top: 2.5%;
  justify-content: center;
  display: flex;
}

.item-noodleSoup {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3%;
}

.item-bread {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3%;
}

.align-on-ids {
  display: flex;
  flex-wrap: wrap;
  flex: 33%;
  margin-bottom: 3%;
}
.align-on-ids-bread {
  display: flex;
  flex-wrap: wrap;
  flex: 33%;
  margin-bottom: 3%;
  margin-left: 5.5rem;
  margin-right: 5.5rem;
}
.align-on-ids-ricePlates {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex: 33%;
  margin-bottom: 3%;
}

.align-altName-prices {
  flex-basis: 100%;
  display: inherit;
  font-style: italic;
  justify-content: space-between;
  margin: 0.5rem auto;
  padding-left: 1rem;
  padding-right: 2rem;
}
.align-altName-prices-bread {
  flex-basis: 100%;
  margin: 0.5rem auto;
  display: inherit;
  font-style: italic;
  justify-content: space-evenly;
}
.align-altName-prices-ricePlates {
  flex-basis: 100%;
  margin: 0.5rem;
  display: inherit;
  font-style: italic;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 2rem;
}

.align-uneven-altName-prices-ricePlates {
  display: flex;
  font-style: italic;
  padding-left: 1rem;
  width: 33%;
  margin: 0.5rem;
  justify-content: space-between;
}

.l-price {
  display: inherit;
  font-style: italic;
  flex-flow: row;
  flex-basis: 100%;
  justify-content: flex-end;
  padding-left: 1rem;
  padding-right: 2rem;
}

.item-sideOrder {
  margin-top: 2%;
  margin-left: 10%;
  margin-right: 10%;
}

.misc-items {
  margin-top: 1.5%;
  display: block;
  text-align: center;
  font-style: italic;
}

.entire-menu-location {
  margin: 0;
}

.entire-menu-footer {
  background-color: #252423;
  min-height: 40vh;
}

.menudrawer {
  display: none;
  position: absolute;
  background-color: rgba(21, 21, 22, 0.966);
  min-width: 17rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.listmenuitem {
  display: block;
}
.listmenuitem a {
  color: white;
  text-decoration: none;
  display: block;
  letter-spacing: 0.2rem;
  font-size: 1.75rem;
}
.listmenuitem a:hover,
.listmenuitem a:active,
.listmenuitem a.active {
  color: #fc520f;
}

.appetizers {
  background-color: #e6e6e6;
  display: flex;
  flex-wrap: wrap;
  scroll-margin-top: 70px;
  justify-content: center;
  height: auto;
}

.header-appetizers {
  width: 100%;
  margin: 3rem;
  margin-top: 9rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 4rem;
  font-family: "Merriweather Sans", sans-serif;
}

.item-appetizers {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 4rem;
}
@media only screen and (max-width: 1024px) {
  .item-appetizers {
    justify-content: center;
    align-items: center;
  }
}

.align-on-ids-appetizers {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 30%;
  margin-left: 3%;
  margin-bottom: 3%;
  flex-direction: column;
}
@media only screen and (max-width: 1024px) {
  .align-on-ids-appetizers {
    width: 40rem;
  }
}

.left-right-column-appetizers {
  display: inherit;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.image-appetizers {
  border-radius: 4px;
  width: 100%;
  height: 40vh;
  object-fit: cover;
}
@media only screen and (max-width: 920px) {
  .image-appetizers {
    width: 40rem;
  }
}

.name-appetizers {
  font-size: 2.25rem;
}

.price-appetizers {
  margin-left: 1rem;
  font-size: 2.25rem;
}

.alt-name-appetizers {
  font-style: italic;
  font-size: 2.25rem;
}

.alt-name-chinese-appetizers {
  letter-spacing: 0.4rem;
  font-size: 2.25rem;
}

.description-appetizers {
  font-size: 1.75rem;
}

.top-seller-appetizers {
  font-size: 2.25rem;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  color: #fc520f;
}

.peanuts-appetizers {
  font-size: 2.25rem;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}

.shellfish-appetizers {
  font-size: 1.55rem;
}

.noodlesoup {
  background-color: #e6e6e6;
  display: flex;
  flex-wrap: wrap;
  scroll-margin-top: 70px;
  justify-content: center;
  height: auto;
}

.header-noodlesoup {
  width: 100%;
  margin: 3rem;
  margin-top: 9rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 4rem;
  font-family: "Merriweather Sans", sans-serif;
}

.item-noodlesoup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 4rem;
}
@media only screen and (max-width: 1024px) {
  .item-noodlesoup {
    justify-content: center;
    align-items: center;
  }
}

.align-on-ids-noodlesoup {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 30%;
  margin-left: 3%;
  margin-bottom: 3%;
  flex-direction: column;
}
@media only screen and (max-width: 1024px) {
  .align-on-ids-noodlesoup {
    width: 40rem;
  }
}

.left-right-column-noodlesoup {
  display: inherit;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.image-noodlesoup {
  border-radius: 4px;
  width: 100%;
  height: 40vh;
  object-fit: cover;
}
@media only screen and (max-width: 920px) {
  .image-noodlesoup {
    width: 40rem;
  }
}

.name-noodlesoup {
  font-size: 2.25rem;
}

.price-noodlesoup {
  margin-left: 2rem;
  font-size: 2.25rem;
}

.alt-name-noodlesoup {
  font-style: italic;
  font-size: 2.25rem;
}

.alt-name-chinese-noodlesoup {
  letter-spacing: 0.4rem;
  font-size: 2.25rem;
}

.description-noodlesoup {
  font-size: 1.75rem;
}

.small-large-price {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
}

.top-seller-noodlesoup {
  font-size: 2.25rem;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  color: #fc520f;
}

.peanuts-noodlesoup {
  font-size: 2.25rem;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}

.shellfish-noodlesoup {
  font-size: 1.55rem;
}

.riceplates {
  background-color: #e6e6e6;
  display: flex;
  flex-wrap: wrap;
  scroll-margin-top: 70px;
  justify-content: center;
  height: auto;
}

.header-riceplates {
  width: 100%;
  font-size: 4rem;
  margin: 3rem;
  margin-top: 9rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Merriweather Sans", sans-serif;
}

.item-riceplates {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 4rem;
}
@media only screen and (max-width: 1024px) {
  .item-riceplates {
    justify-content: center;
    align-items: center;
  }
}

.align-on-ids-riceplates {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 30%;
  margin-left: 3%;
  margin-bottom: 3%;
  flex-direction: column;
}
@media only screen and (max-width: 1024px) {
  .align-on-ids-riceplates {
    width: 40rem;
  }
}

.left-right-column-riceplates {
  display: inherit;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.image-riceplates {
  border-radius: 4px;
  width: 100%;
  height: 40vh;
  object-fit: cover;
}
@media only screen and (max-width: 920px) {
  .image-riceplates {
    width: 40rem;
  }
}

.name-riceplates {
  font-size: 2.25rem;
}

.price-riceplates {
  margin-left: 1rem;
  font-size: 2.25rem;
}

.alt-name-riceplates {
  font-style: italic;
  font-size: 2.25rem;
}

.alt-name-chinese-riceplates {
  letter-spacing: 0.4rem;
  font-size: 2.25rem;
}

.description-riceplates {
  font-size: 1.75rem;
}

.top-seller-riceplates {
  font-size: 2.25rem;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  color: #fc520f;
}

.peanuts-riceplates {
  font-size: 2.25rem;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}

.shellfish-riceplates {
  font-size: 1.55rem;
}

.bread {
  background-color: #e6e6e6;
  display: flex;
  flex-wrap: wrap;
  min-height: 100vh;
  scroll-margin-top: 70px;
  justify-content: center;
}

.header-bread {
  width: 100%;
  font-size: 4rem;
  margin: 3rem;
  margin-top: 9rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Merriweather Sans", sans-serif;
}

.item-bread {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 4rem;
}
@media only screen and (max-width: 920px) {
  .item-bread {
    justify-content: center;
    align-items: center;
  }
}

.align-on-ids-bread {
  display: flex;
  flex-wrap: wrap;
  width: 40rem;
  margin-bottom: 3%;
  flex-direction: column;
}
@media only screen and (max-width: 920px) {
  .align-on-ids-bread {
    width: 35rem;
  }
}

.image-bread {
  border-radius: 4px;
  width: 100%;
  height: 40vh;
  object-fit: cover;
}
@media only screen and (max-width: 920px) {
  .image-bread {
    width: 100%;
  }
}

.left-right-column-bread {
  display: inherit;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.name-bread {
  font-size: 2.25rem;
}

.price-bread {
  margin-left: 1rem;
  font-size: 2.25rem;
}

.alt-name-bread {
  font-style: italic;
  font-size: 2.25rem;
}

.alt-name-chinese-bread {
  letter-spacing: 0.4rem;
  font-size: 2.25rem;
}

.description-bread {
  font-size: 1.75rem;
}

.top-seller-bread {
  font-size: 2.25rem;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  color: #fc520f;
}

.peanuts-bread {
  font-size: 2.25rem;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}

.vermicelli {
  background-color: #e6e6e6;
  display: flex;
  flex-wrap: wrap;
  scroll-margin-top: 70px;
  justify-content: center;
  height: auto;
}

.header-vermicelli {
  width: 100%;
  font-size: 4rem;
  margin: 3rem;
  margin-top: 9rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Merriweather Sans", sans-serif;
}

.item-vermicelli {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 4rem;
}
@media only screen and (max-width: 1024px) {
  .item-vermicelli {
    justify-content: center;
    align-items: center;
  }
}

.align-on-ids-vermicelli {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 30%;
  margin-left: 3%;
  margin-bottom: 3%;
  flex-direction: column;
}
@media only screen and (max-width: 1024px) {
  .align-on-ids-vermicelli {
    width: 40rem;
  }
}

.left-right-column-vermicelli {
  display: inherit;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.image-vermicelli {
  border-radius: 4px;
  width: 100%;
  height: 40vh;
  object-fit: cover;
}
@media only screen and (max-width: 920px) {
  .image-vermicelli {
    width: 40rem;
  }
}

.name-vermicelli {
  font-size: 2.25rem;
}

.price-vermicelli {
  margin-left: 1rem;
  font-size: 2.25rem;
}

.alt-name-vermicelli {
  font-style: italic;
  font-size: 2.25rem;
}

.alt-name-chinese-vermicelli {
  letter-spacing: 0.4rem;
  font-size: 2.25rem;
}

.description-vermicelli {
  font-size: 1.75rem;
}

.top-seller-vermicelli {
  font-size: 2.25rem;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  color: #fc520f;
}

.peanuts-vermicelli {
  font-size: 2.25rem;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}

.shellfish-vermicelli {
  font-size: 1.55rem;
}

.sideorder {
  background-color: #e6e6e6;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  scroll-margin-top: 70px;
  justify-content: center;
  align-content: center;
  min-height: 100vh;
}

.header-sideorder {
  width: 100%;
  font-size: 4rem;
  margin-bottom: 3rem;
  margin-top: 9rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Merriweather Sans", sans-serif;
}

.item-sideorder {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0 30rem;
}
@media only screen and (max-width: 1024px) {
  .item-sideorder {
    justify-content: center;
    align-content: center;
    margin: 0 0;
  }
}

.align-on-ids-sideorder {
  display: flex;
  margin: 0.25rem 0.1rem;
  flex-direction: column;
}
@media only screen and (max-width: 1024px) {
  .align-on-ids-sideorder {
    margin: 0;
  }
}

.left-right-column {
  display: inherit;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.name-sideorder {
  letter-spacing: 0.2rem;
  font-size: 2rem;
}
.name-sideorder-indent {
  letter-spacing: 0.2rem;
  list-style: none;
  margin-left: 1rem;
  font-size: 2rem;
}

.price-sideorder {
  margin-left: 1rem;
  font-size: 2rem;
  color: rgba(190, 76, 0, 0.973);
}

.alt-name-sideorder {
  font-style: italic;
  font-size: 2rem;
  padding-left: 2rem;
  color: #2e2c2c;
}

.beverages {
  background-color: #e6e6e6;
  display: flex;
  flex-wrap: wrap;
  scroll-margin-top: 70px;
  justify-content: center;
  height: auto;
}

.header-beverages {
  width: 100%;
  font-size: 4rem;
  margin: 3rem;
  margin-top: 9rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Merriweather Sans", sans-serif;
}

.item-beverages {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 0 4rem;
}
@media only screen and (max-width: 1024px) {
  .item-beverages {
    justify-content: center;
    align-items: center;
  }
}

.align-on-ids-beverages {
  display: flex;
  flex-wrap: wrap;
  flex-basis: 30%;
  margin-left: 3%;
  margin-bottom: 3%;
  flex-direction: column;
}
@media only screen and (max-width: 1024px) {
  .align-on-ids-beverages {
    width: 30rem;
  }
}

.left-right-column-beverages {
  display: inherit;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.name-beverages {
  font-size: 2.25rem;
}

.price-beverages {
  margin-left: 1rem;
  font-size: 2.25rem;
}

.alt-name-beverages {
  font-style: italic;
  font-size: 2.25rem;
}

.description-beverages {
  font-size: 1.75rem;
}

.image-beverages {
  border-radius: 4px;
  width: 100%;
  height: 70vh;
  margin-bottom: 0.5rem;
  object-fit: cover;
}
@media only screen and (max-width: 920px) {
  .image-beverages {
    width: 30rem;
  }
}

/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/
:root {
  font-size: 62.5%;
}
@media only screen and (max-width: 75em) {
  :root {
    font-size: 56.25%;
  }
}
@media only screen and (max-width: 56.25em) {
  :root {
    font-size: 50%;
  }
}
@media only screen and (min-width: 112.5em) {
  :root {
    font-size: 75%;
  }
}

body {
  min-height: 100vh;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
